<template>
  <div class="program--deload-workouts">
    <v-row>
      <v-col cols="12" sm="10">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Program Deload Workouts</h2>

          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            @click="saveForLater"
            :loading="loading"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-8"
            @click="saveAndComplete"
            :loading="loading"
            :disabled="false"
            :text="true"
            depressed
          >
            <span v-if="!isCompleted"> Complete Program </span>
            <span v-else> Save Changes </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="10">
        <deload-workout-form :model="program" :deload-weeks="deloadWeeks" />
        <deload-workouts
          class="pb-12"
          :model="program"
          :deload-workouts="workouts"
        />
      </v-col>
    </v-row>

    <v-toast ref="errorToast" />
  </div>
</template>

<script>
import DeloadWorkoutForm from '@/components/forms/workouts/DeloadWorkoutForm'
import DeloadWorkouts from '@/components/elements/programs/DeloadWorkouts'
import ProgramMixin from '../mixins/ProgramMixin'
import VToast from '@/components/elements/Toast'

import { map, fill } from 'lodash'
import { mapGetters } from 'vuex'
import { mdiCheck } from '@mdi/js'

export default {
  name: 'ProgramDeloadWorkouts',

  mixins: [ProgramMixin],

  components: {
    DeloadWorkoutForm,
    DeloadWorkouts,
    VToast,
  },

  data() {
    return {
      icons: {
        check: mdiCheck,
      },
    }
  },

  computed: {
    ...mapGetters({
      workouts: 'programs/getSelectedProgramDeloadWorkouts',
    }),

    weekCount() {
      return this.program.week_count
    },

    deloadWeekCount() {
      return this.program.deload_week_count
    },

    deloadWeeks() {
      const _deload = fill(Array(this.deloadWeekCount), this.weekCount)

      const _weeks = map(_deload, (week, index) => {
        return week + (index + 1)
      })

      return _weeks
    },
  },
}
</script>
